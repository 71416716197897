import React, {useEffect} from 'react'
// eslint-disable-next-line
import { Routes, Route, Link } from "react-router-dom";
import {Container, Dropdown, Nav, Badge, Navbar} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBell, faSignOutAlt, faUser} from '@fortawesome/free-solid-svg-icons'
import {faCircle} from '@fortawesome/free-regular-svg-icons'
import {colors} from '../../../tools/constants';

export default function SubNavBar(){

    useEffect(() => {
       
    }, []);

    const menus = [{text: "Físicas", link: "/clientes/personas"},
                    {text: "Jurídicas", link: "/clientes/empresas"}];

    const checkPage = function(key){
        const actualUri = window.location.pathname.split('/')[2];
        const actualMenu = menus[key].link.split('/')[2];

        if(actualUri === actualMenu){
            return(
                <Link to={menus[key].link} className="subnavbar-menu-active pe-3 ps-3 text-light">{menus[key].text}</Link>
            )
        }else{
            return(
                <Link to={menus[key].link} className="subnavbar-menu-not-active pe-3 ps-3">{menus[key].text}</Link>
            )
        }
    };
    
    return(
        <div style={{marginBottom: 165}}>
            <Navbar fixed="top" style={{background: `${colors().brand_clear_blue}`, marginTop: 123, zIndex: '9', height: 41}}>
                <Container>
                    <Nav className="flex-grow-1 justify-content-center pb-0">
                        {menus.map((menu, i) => (
                            <div key={i}>{checkPage(i)}</div>
                        ))}
                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}