import { params } from '../tools/constants';
import { getUserBusinessId } from './user.controller';

export const post = async function (url, token, body) {
    try {
        let businessId = getUserBusinessId();
        let headers = {
            'Accept': 'application/x-www-form-urlencoded',
            'Origin': 'http://localhost:3000',
            'Content-Type': 'application/json',
            'x-access-token': token
        };

        if (businessId != null) {
            if (businessId != "N/D" && businessId != "N/A") {
                headers = {
                    'Accept': 'application/x-www-form-urlencoded',
                    'Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                    'businessId': businessId
                }
            }
        }

        let response = await fetch(params().apiUri + url, {
            method: 'POST',
            mode: "cors",
            headers: headers,
            body: JSON.stringify(body),
        });

        return response;
    } catch (error) {
        console.log("error", error);
        return ({ rdo: 1, mensaje: "Ha ocurrido un error" });
    };
}

export const put = async function (url, token, body) {
    try {
        let businessId = getUserBusinessId();
        let headers = {
            'Accept': 'application/x-www-form-urlencoded',
            'Origin': 'http://localhost:3000',
            'Content-Type': 'application/json',
            'x-access-token': token
        };

        if (businessId != null) {
            if (businessId != "N/D" && businessId != "N/A") {
                headers = {
                    'Accept': 'application/x-www-form-urlencoded',
                    'Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                    'businessId': businessId
                }
            }
        }

        let response = await fetch(params().apiUri + url, {
            method: 'PUT',
            mode: "cors",
            headers: headers,
            body: JSON.stringify(body),
        });

        return response;
    } catch (error) {
        console.log("error", error);
        return ({ rdo: 1, mensaje: "Ha ocurrido un error" });
    };
}

export const get = async function (url, token) {
    try {
        let businessId = getUserBusinessId();
        let headers = {
            'Accept': 'application/x-www-form-urlencoded',
            'Origin': 'http://localhost:3000',
            'Content-Type': 'application/json',
            'x-access-token': token
        };

        if (businessId != null) {
            if (businessId != "N/D" && businessId != "N/A") {
                headers = {
                    'Accept': 'application/x-www-form-urlencoded',
                    'Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                    'businessId': businessId
                }
            }
        }

        console.log(headers)

        let response = await fetch(params().apiUri + url, {
            method: 'GET',
            mode: "cors",
            headers: headers
        });

        return response;
    } catch (error) {
        console.log("error", error);
        return ({ rdo: 1, mensaje: "Ha ocurrido un error" });
    };
}

export const del = async function (url, token, body) {
    try {
        let businessId = getUserBusinessId();
        let headers = {
            'Accept': 'application/x-www-form-urlencoded',
            'Origin': 'http://localhost:3000',
            'Content-Type': 'application/json',
            'x-access-token': token
        };

        if (businessId != null) {
            if (businessId != "N/D" && businessId != "N/A") {
                headers = {
                    'Accept': 'application/x-www-form-urlencoded',
                    'Origin': 'http://localhost:3000',
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                    'businessId': businessId
                }
            }
        }

        let response = await fetch(params().apiUri + url, {
            method: 'DELETE',
            mode: "cors",
            headers: headers,
            body: JSON.stringify(body),
        });

        return response;
    } catch (error) {
        console.log("error", error);
        return ({ rdo: 1, mensaje: "Ha ocurrido un error" });
    };
}
