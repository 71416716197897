import React, {useEffect} from 'react'
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Col, Badge, Dropdown, Button} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash} from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import moment from 'moment'
import 'moment/locale/es-mx';
import {get, put} from '../../../controllers/endpoints.controller';
import {getUserToken, logout} from '../../../controllers/user.controller';
import { processDate, processDateCalendar, timeDue } from '../../../tools/tools';


export default function Calendar(){

    moment.locale('es-mx');
    const PAGE_TITLE = "Calendario";
    const PAGE_ICON = faCalendarAlt;
    const [tasks, setTasks] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [lastSyncGoogle, setLastSyncGoogle] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;

        document.getElementsByClassName('fc-today-button')[0].innerHTML = 'Hoy'
        document.getElementsByClassName('fc-dayGridDay-button')[0].innerHTML = 'Día'
        document.getElementsByClassName('fc-dayGridWeek-button')[0].innerHTML = 'Semana'
        document.getElementsByClassName('fc-dayGridMonth-button')[0].innerHTML = 'Mes'

        getTasks();
        getLastSyncGoogle()
    }, []);

    
    const getTasks = async function(){
        const req = await get('/tasks/calendar', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            processEvents(res);
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const getLastSyncGoogle = async function(){
        const req = await get('/config/lastSyncGoogle', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            setLastSyncGoogle(`Última sincronización con Google Calendar: ${res.value}`)
        }else if(req.status === 210){
            
        }else if(req.status === 403){
            logout();
        }else{
            alert(res.message);
        }
    }

    const processEvents = function(data){
        let result = [];

        for(let e of data){
            if(e.dueDate != null){
                result.push({
                    title: e.title,
                    date: processDateCalendar(e.dueDate)
                });
            }
        }
        
        setEvents(result);
    }


    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards p-4 mb-5'>
                        <FullCalendar
                            plugins={[ dayGridPlugin, bootstrapPlugin ]}
                            locale='esLocale'
                            initialView="dayGridMonth"
                            aspectRatio='2'
                            dayHeaderFormat={{weekday: 'long'}}
                            themeSystem='bootstrap'
                            events={events}
                            headerToolbar={{
                                start: 'title',
                                center: '',
                                end: 'today prev,next dayGridDay dayGridWeek dayGridMonth'
                            }}
                            />
                        <p className='mb-0 mt-3' style={{fontSize: 13}}>{lastSyncGoogle}</p>
                    </Card>
                </Row>
            </Container>

            
            
        </div>
    )
}