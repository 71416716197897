import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
import moment from 'moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Table, Dropdown, Badge, Modal, Form, ListGroup, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCheck, faCircle, faCoins, faEllipsisV, faEye, faKey, faPassport, faPen, faPlus, faPlusCircle, faSchool, faSearch, faTimes, faTrash, faUser } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import SubNavBarC from "../navbar/SubNavbarCustomers";
import SubNavBarD from "../navbar/SubNavbarDefendants";
import { del, get, post, put } from '../../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../../controllers/user.controller';
import { colors } from '../../../tools/constants';


export default function UserDetails(props) {

    const PAGE_TITLE = "Usuario";
    const PAGE_ICON = faUser;

    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(0);
    const [selectedIsLawer, setSelectedIsLawer] = React.useState(false);
    const [selectedIsEnabled, setSelectedIsEnabled] = React.useState(true);
    const [name, setName] = React.useState('');
    const [showSearchCustomerModal, setShowSearchCustomerModal] = React.useState(false);
    const [customers, setCustomers] = React.useState([]);
    const [selectedCustomer, setSelectedCustomer] = React.useState({});
    const [isEditing, setIsEditing] = React.useState(false)
    const [newPassword1, setNewPassword1] = React.useState('')
    const [newPassword2, setNewPassword2] = React.useState('')
    const [passwordVerification, setPasswordVerification] = React.useState('')
    const [showEditPasswordModal, setShowEditPasswordModal] = React.useState(false);
    const [confirmButton, setConfirmButton] = React.useState('Confirmar');
    const [businesses, setBusinesses] = React.useState([]);

    const [showSureDelete, setShowSureDelete] = React.useState(false);
    const handleCloseSureDelete = () => setShowSureDelete(false);
    const handleShowSureDelete = (name, id) => {
        setShowSureDelete(true);
    };

    const handleCloseEditPasswordModal = () => {
        setShowEditPasswordModal(false);
    };

    const handleOpenEditPasswordModal = () => {
        setShowEditPasswordModal(true);
    };

    const handleNewPassword1 = (event) => {
        setNewPassword1(event.target.value);
        checkPassword()
    };

    const handleNewPassword2 = (event) => {
        setNewPassword2(event.target.value);
        checkPassword()
    };

    const handleRole = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleIsLawer = (event) => {
        setSelectedIsLawer(event.target.value);
    };

    const handleIsEnabled = (event) => {
        setSelectedIsEnabled(event.target.value);
    };

    const handleIsEditing = () => {
        setIsEditing(!isEditing)
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;

        getRoles()

    }, []);

    const handleCloseSearchCustomerModal = () => {
        setShowSearchCustomerModal(false);
    };

    const handleOpenSearchCustomerModal = () => {
        setShowSearchCustomerModal(true);
    };

    const handleCustomerSelected = (index, isBusiness) => {
        for (let c of customers) {
            if (c.id == index && c.isBusiness == isBusiness) {
                associateBusiness(c.id)
                //setSelectedCustomer(c);
                console.log(c)
                //document.getElementById('textCustomer').value = c.isBusiness == true ? (`CUIT ${c.cuit} - ${c.name}`) : `DNI ${c.dni} - ${c.surname.toUpperCase()}, ${c.name}`;
            }
        }
    };

    const associateBusiness = async function (businessId) {
        const req = await post(`/users/business/associate`, getUserToken(), { businessId: businessId, userId: getUserId() });
        const res = await req.json();

        if (req.status === 201) {
            getUser();
            handleCloseSearchCustomerModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const disassociateBusiness = async function (businessId) {
        const req = await del(`/users/business/disassociate`, getUserToken(), { businessId: businessId, userId: getUserId() });
        const res = await req.json();

        if (req.status === 201) {
            getUser();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleSearchCustomers = async (event) => {
        if (event.target.value.length > 2) {
            const chain = {
                chain: event.target.value
            };
            const req = await post(`/customers/search`, getUserToken(), chain);
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setCustomers(res)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
                console.log(res)
            }
        } else {
            setCustomers([])
        }
    };

    const editPassword = async function () {
        if (checkPassword()) {
            const req = await put(`/users/passwordById`, getUserToken(), { password: newPassword1, userId: getUserId() });
            const res = await req.json();

            if (req.status === 201) {

                handleCloseEditPasswordModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            alert('La contraseña no cumple con los requisitos de seguridad')
        }

    }

    const checkPassword = () => {
        const p1 = document.getElementById('textPassword1').value;
        const p2 = document.getElementById('textPassword2').value;
        const ss = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const uc = /([A-Z])+/
        const nu = /([0-9])+/
        let specialSymbol = false;
        let upperCase = false;
        let number = false;
        let textLenght = false;
        let confirm = false;

        if (ss.test(p1)) {
            specialSymbol = true;
        }

        if (uc.test(p1)) {
            upperCase = true;
        }

        if (nu.test(p1)) {
            number = true;
        }

        if (p1.length > 7) {
            textLenght = true;
        }

        if (p1 === p2) {
            confirm = true;
        }

        setPasswordVerification(
            <div className='mt-3'>
                <p className='m-0'>{upperCase ? <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }}></FontAwesomeIcon>}<span className='ps-2'>1 letra mayúscula</span></p>
                <p className='m-0'>{number ? <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }}></FontAwesomeIcon>}<span className='ps-2'>1 número</span></p>
                <p className='m-0'>{specialSymbol ? <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }}></FontAwesomeIcon>}<span className='ps-2'>1 carácter especial</span></p>
                <p className='m-0'>{textLenght ? <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }}></FontAwesomeIcon>}<span className='ps-2'>Más de 7 caracteres</span></p>
                <p className='m-0'>{confirm ? <FontAwesomeIcon icon={faCircle} style={{ color: 'green' }}></FontAwesomeIcon> : <FontAwesomeIcon icon={faCircle} style={{ color: 'red' }}></FontAwesomeIcon>}<span className='ps-2'>Contraseñas coinciden</span></p>
            </div>
        )

        return specialSymbol && upperCase && number && confirm && textLenght;
    }

    const getUserId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getRoles = async function () {
        const req = await get('/users/roles', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setRoles(res);
            getUser();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    const getUser = async function () {
        if (getUserId() != -1) {
            const req = await get(`/users/byId/${getUserId()}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                processUser(res)
                setName(`${res.name} ${res.surname}`)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await get(`/users/byToken`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                processUser(res)
                setName(`${res.name} ${res.surname}`)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }

    }

    const processUser = (data) => {
        if (data.token == getUserToken()) {
            window.location.href = "/configuracion/usuarios/detalles";
        }
        document.getElementById('textName').value = data.name;
        document.getElementById('textSurname').value = data.surname;
        document.getElementById('textDni').value = data.dni;
        document.getElementById('textEmail').value = data.email;
        document.getElementById('textFolio').value = data.folio;
        document.getElementById('textTomo').value = data.tomo;
        document.getElementById('selectRole').value = data.roleId;
        document.getElementById('selectIsLawer').value = data.isLawer;
        document.getElementById('selectIsEnabled').value = data.enabled;
        setSelectedIsLawer(data.isLawer);
        setSelectedIsEnabled(data.enabled)
        setSelectedRole(data.roleId);

        if (data.Person) {
            const c = data.Person;
            document.getElementById('textCustomer').value = `DNI ${c.dni} - ${c.surname.toUpperCase()}, ${c.name}`;
        }

        if (data.UserBusinesses) {
            setBusinesses(data.UserBusinesses)
            //const c = data.Business;
            //document.getElementById('textCustomer').value = `CUIT ${c.cuit} - ${c.name}`;
        }
    }

    const editUser = async function () {
        let businessId = null;
        let personId = null;

        if (selectedCustomer) {
            if (selectedCustomer.isBusiness) {
                businessId = selectedCustomer.id
            } else {
                personId = selectedCustomer.id
            }
        }

        const user = {
            roleId: selectedRole,
            businessId: businessId,
            personId: personId,
            name: document.getElementById('textName').value,
            surname: document.getElementById('textSurname').value,
            email: document.getElementById('textEmail').value,
            isLawer: selectedIsLawer,
            folio: document.getElementById('textFolio').value,
            tomo: document.getElementById('textTomo').value,
            dni: document.getElementById('textDni').value,
            userId: getUserId(),
            enabled: selectedIsEnabled
        }

        const req = await put('/users', getUserToken(), user);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = '/sistema'
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteUser = async function () {
        const body = {
            userId: getUserId()
        }
        const req = await del('/users/', getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = "/sistema";
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>{PAGE_TITLE}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{name}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-3'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    {getUserId() != -1 ?
                                        <Link to={`/sistema`} className='link-primary'>
                                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Configuración del Sistema
                                        </Link>
                                        :
                                        <span style={{ fontSize: 18 }}>Mis datos</span>
                                    }

                                </td>
                                <td className='content-options rows right'>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleOpenEditPasswordModal}><FontAwesomeIcon icon={faKey} style={{ marginRight: 11, marginLeft: 3 }} />Cambiar contraseña</Dropdown.Item>
                                            <Dropdown.Item onClick={handleIsEditing}><FontAwesomeIcon icon={faPen} style={{ marginRight: 11, marginLeft: 3 }} />Modificar</Dropdown.Item>
                                            <Dropdown.Item onClick={handleShowSureDelete}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 12, marginLeft: 4 }} />Eliminar</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-4 mb-4'>
                            <Row>
                                <Col xs={6}>
                                    <table className='w-100'>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold', width: 170 }}>Nombre</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textName" placeholder="" readOnly={!isEditing} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Apellido</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textSurname" placeholder="" readOnly={!isEditing} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>DNI</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textDni" placeholder="" readOnly={!isEditing} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Rol</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Select className='cover content rows element' id="selectRole" disabled={!isEditing} onChange={(event) => handleRole(event)}>
                                                        <option value="0">Seleccionar...</option>
                                                        {roles.map((p, i) => (
                                                            <option key={i} id={i} value={p.id}>{p.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Correo electrónico</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textEmail" placeholder="" readOnly={!isEditing} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </table>
                                </Col>
                                <Col xs={6}>
                                    <table className='w-100'>

                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Abogado</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Select className='cover content rows element' id="selectIsLawer" disabled={!isEditing} onChange={(event) => handleIsLawer(event)}>
                                                        <option value={false}>No</option>
                                                        <option value={true}>Si</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Folio</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textFolio" placeholder="" readOnly={!isEditing} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Tomo</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Control type="text" id="textTomo" placeholder="" readOnly={!isEditing} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-2' style={{ fontWeight: 'bold' }}>Habilitado</td>
                                            <td className='pt-2'>
                                                <Form.Group>
                                                    <Form.Select className='cover content rows element' id="selectIsEnabled" disabled={!isEditing} onChange={(event) => handleIsEnabled(event)}>
                                                        <option value={true}>Si</option>
                                                        <option value={false}>No</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </td>
                                        </tr>
                                    </table>
                                </Col>
                            </Row>
                            <Form>


                            </Form>

                            {isEditing ?
                                <div className='mt-5' style={{ textAlign: 'right' }}>
                                    <Button variant="success" onClick={editUser}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 8 }} />Confirmar cambios</Button>
                                </div>

                                :
                                <div></div>
                            }

                        </Container>
                    </Card>
                </Row>


                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Clientes asociados
                                </td>
                                <td className='content-options rows right'>
                                    

                                    <Button variant="success" onClick={handleOpenSearchCustomerModal}><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Asociar cliente</Button>
                                </td>
                            </tr>
                        </table>

                        <Container className='mt-2 mb-4'>
                            <Row>
                                <Container className='pt-3'>

                                    {businesses.map((r, i) => (
                                        <div>
                                            <div className='d-none d-sm-block'>
                                                <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                    <Col xs={1} className='align-center-vertically'>
                                                        <p className='m-0'>{r.Business.code}</p>

                                                    </Col>
                                                    <Col xs={10} className='align-center-vertically-v2'>
                                                        <div>
                                                            <p className='m-0'>{r.Business.legalName}</p>
                                                        </div>

                                                    </Col>


                                                    <Col xs={1} className='align-center-vertically-v2'>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={() => disassociateBusiness(r.Business.id)}><FontAwesomeIcon icon={faTimes} style={{ marginRight: 11, marginLeft: 3 }} />Desasociar</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>


                                        </div>

                                    ))}
                                </Container>
                            </Row>
                        </Container>
                    </Card>
                </Row>
            </Container>

            <Modal show={showSearchCustomerModal} size='lg' onHide={handleCloseSearchCustomerModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="" autoFocus placeholder="Buscar..." onChange={(event) => handleSearchCustomers(event)} />
                    {customers.length > 0 ? <p className='mt-4 mb-0'>Resultado de búsqueda:</p> : <span></span>}
                    <ListGroup className='mt-2'>
                        {customers.map((c, i) => (
                            <ListGroup.Item key={i} onClick={() => handleCustomerSelected(c.id, c.isBusiness)}>{c.isBusiness == true ? (<div><p className='m-0' style={{ fontSize: 12 }}>Persona jurídica</p><p className='m-0'></p>CUIT {c.cuit} - {c.name}</div>) : (<div><p className='m-0' style={{ fontSize: 12 }}>Persona física</p><p className='m-0'></p>DNI {c.dni} - {c.surname.toUpperCase()}, {c.name}</div>)}</ListGroup.Item>
                        ))}
                    </ListGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSearchCustomerModal}>
                        Cerrar
                    </Button>
                    <Link to='/clientes/personas'>
                        <Button variant="primary">
                            Crear nuevo cliente
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditPasswordModal} onHide={handleCloseEditPasswordModal}>
                <Modal.Header>
                    <Modal.Title>Cambiar contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mt-0'>
                        <Form.Text>Nueva contraseña</Form.Text>
                        <Form.Control type="password" id="textPassword1" placeholder="" onChange={(event) => handleNewPassword1(event)} />
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Confirmar contraseña</Form.Text>
                        <Form.Control type="password" id="textPassword2" placeholder="" onChange={(event) => handleNewPassword2(event)} />
                    </Form.Group>
                    {passwordVerification}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditPasswordModal}>
                        Cerrar
                    </Button>
                    <Button variant="success" onClick={editPassword}>
                        {confirmButton}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSureDelete} centered onHide={handleCloseSureDelete}>
                <Modal.Header closeButtonSureDelete>
                    <Modal.Title>Eliminar usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Está seguro de eliminar al usuario?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSureDelete}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={deleteUser}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}