import React, { useEffect } from 'react'
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Col, Badge, Dropdown, Button, Form, Modal, ToggleButton, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faFilePdf, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faGavel, faTrash, faSearch, faTasks, faArrowRight, faEye, faFilter, faDownload, faPen } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from "../navbar/Navbar";
import WebFont from 'webfontloader';
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import 'moment/locale/es-mx';
import { get, put, post } from '../../../controllers/endpoints.controller';
import { getUserId, getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import { processDate, processDateCalendar, timeDue, timeDueWithBadge } from '../../../tools/tools';
import { params } from '../../../tools/constants'
import { triggerBase64Download } from 'common-base64-downloader-react';


export default function Tasks() {

    moment.locale('es-mx');
    const PAGE_TITLE = "Tareas";
    const PAGE_ICON = faTasks;
    const [tasks, setTasks] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [error, setError] = React.useState(<div></div>);
    const [proceedings, setProceedings] = React.useState([]);
    const [lawers, setLawers] = React.useState([]);
    const [lawer, setLawer] = React.useState({});
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskProceedingId, setNewTaskProceedingId] = React.useState(null);
    const [newTaskDate, setNewTaskDate] = React.useState(null);
    const [newTaskReminder, setNewTaskReminder] = React.useState(null);
    const [newTaskNotificationChecked, setNewTaskNotificationChecked] = React.useState(false);
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const [selectedLawer, setSelectedLawer] = React.useState({});

    const [selectedLawerFilter, setSelectedLawerFilter] = React.useState(0);

    const [showFilterModal, setShowFilterModal] = React.useState(false);
    const handleCloseFilterModal = () => { setShowFilterModal(false); };
    const handleShowFilterModal = () => {
        setShowFilterModal(true);
    };

    const [showEditTaskModal, setShowEditTaskModal] = React.useState(false);
    const handleCloseEditTaskModal = () => { setShowEditTaskModal(false); };
    const handleShowEditTaskModal = (t) => {
        setEditTaskProceeding(t.proceedingId)
        setEditTaskUser(t.userId)
        setEditTaskTitle(t.title)
        setEditTaskDescription(t.description)
        setEditTaskDate(t.dueDate == null ? null : new Date(t.dueDate))
        setEditTaskReminder(null)
        setShowEditTaskModal(true);
        setEditTaskId(t.id)
    };

    const [selectedOnlyMine, setSelectedOnlyMine] = React.useState(true);
    const [selectedExpired, setSelectedExpired] = React.useState(true);
    const [selectedToday, setSelectedToday] = React.useState(true);
    const [selectedAfter, setSelectedAfter] = React.useState(true);
    const [selectedWithoutDue, setSelectedWithoutDue] = React.useState(true);
    const [selectedAll, setSelectedAll] = React.useState(true);

    const [editTaskId, setEditTaskId] = React.useState(null);
    const [editTaskProceeding, setEditTaskProceeding] = React.useState(null);
    const [editTaskUser, setEditTaskUser] = React.useState(null);
    const [editTaskTitle, setEditTaskTitle] = React.useState(null);
    const [editTaskDescription, setEditTaskDescription] = React.useState(null);
    const [editTaskDate, setEditTaskDate] = React.useState(null);
    const [editTaskReminder, setEditTaskReminder] = React.useState(null);



    const handleFilterShow = (event, type) => {
        switch (type) {
            case "expired":
                setSelectedExpired(event.target.checked);
                checkAllSelected(event.target.checked, selectedToday, selectedAfter, selectedWithoutDue)
                break;
            case "today":
                setSelectedToday(event.target.checked);
                checkAllSelected(selectedExpired, event.target.checked, selectedAfter, selectedWithoutDue)
                break;
            case "after":
                setSelectedAfter(event.target.checked);
                checkAllSelected(selectedExpired, selectedToday, event.target.checked, selectedWithoutDue)
                break;
            case "withoutdue":
                setSelectedWithoutDue(event.target.checked);
                checkAllSelected(selectedExpired, selectedToday, selectedAfter, event.target.checked)
                break;
            case "all":
                if (event.target.checked) {
                    checkAllSelected(true, true, true);
                    setSelectedExpired(true);
                    setSelectedAfter(true);
                    setSelectedToday(true);
                    setSelectedWithoutDue(true);
                } else {
                    checkAllSelected(false, false, false);
                    setSelectedExpired(false);
                    setSelectedAfter(false);
                    setSelectedToday(false);
                    setSelectedWithoutDue(false);
                }

                break;
        }
    };

    const checkAllSelected = (expired, today, after, withoutdue) => {
        setSelectedAll(expired && today && after && withoutdue);
    }

    const handleCloseNewTaskModal = () => { setShowNewTaskModal(false); };
    const handleShowNewTaskModal = () => {
        setError()
        setNewTaskNotificationChecked(false)
        setShowNewTaskModal(true);
    };

    const handleOnlyMine = (event) => {
        setSelectedOnlyMine(event.target.checked)
    };

    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskNotification = (event) => {
        setNewTaskNotificationChecked(event)
    };

    const handleNewTaskProceeding = (event) => {
        setNewTaskProceedingId(event.target.value)
    };


    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };

    const handleEditTaskUser = (event) => {
        setEditTaskUser(event.target.value)
    };

    const handleEditTaskProceeding = (event) => {
        setEditTaskProceeding(event.target.value)
    };

    const handleEditTaskReminder = (event) => {
        setEditTaskReminder(event.target.value)
    };

    const handleEditTaskDate = (event) => {
        setEditTaskDate(event);
    };

    const handleLawerFilter = (event) => {
        setSelectedLawerFilter(event.target.value);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;

        registerLocale("es", { ...es, options: { ...es.options, weekStartsOn: 0 } });
        getData();

    }, []);

    const getData = async () => {
        await getLawers();
        getAllProceedings();
        getReminderOptions();
        getTasks(true, true, true, getUserId());
        setSelectedLawerFilter(getUserId())
    }

    const getTasks = async function (expired, today, after, withoutdue, userId) {
        let body;

        if (userId) {
            body = {
                expired: expired,
                today: today,
                after: after,
                withoutdue: withoutdue,
                lawer: userId
            }
        } else {
            body = {
                expired: expired,
                today: today,
                after: after,
                withoutdue: withoutdue,
                lawer: selectedLawerFilter
            }
        }
        const req = await post(`/tasks/filters/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            processTasks(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getAllProceedings = async function () {
        const req = await get(`/proceedings/all`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setProceedings(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getReminderOptions = async function () {
        const req = await get(`/tasks/reminderOptions/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReminderOptions(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processTasks = function (data) {
        let result = [];
        let aux;

        for (let t of data) {
            if (t.Proceeding) {
                switch (t.Proceeding.Status.name) {
                    case "Deuda":
                        aux = `GDD - ${t.Proceeding.Debt.name}`;
                        break;
                    case "Judicial":
                        aux = `Expediente - ${t.Proceeding.CourtFile.name}`;
                        break;
                }
            } else {
                aux = "";
            }

            if (t.Proceeding.Status.name == "Judicial") {
                t.proceedingUrl = `/expedientes/detalles/?id=${t.Proceeding.id}`
            } else if (t.Proceeding.Status.name == "Deuda") {
                t.proceedingUrl = `/moras/detalles/?id=${t.Proceeding.id}`
            }

            t.proceedingName = aux;
            result.push(t);
        }

        setTasks(result);
    }

    const setTaskAsCompleted = async function (taskId, proceedingId) {
        const req = await put(`/tasks/byProceedingId/asCompleted/`, getUserToken(), { taskId: taskId, proceedingId: proceedingId });
        const res = await req.json();

        if (req.status === 200) {
            getTasks(selectedExpired, selectedToday, selectedAfter, selectedWithoutDue);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createTask = async function () {
        if (checkNewTask()) {
            const task = {
                proceedingId: newTaskProceedingId != '0' ? newTaskProceedingId : null,
                userId: newTaskUser,
                dueDate: newTaskDate,
                alertId: newTaskReminder,
                title: document.getElementById("newTaskTitle").value,
                description: document.getElementById("newTaskDescription").value,
                isEvent: true,
                sendNotification: newTaskNotificationChecked
            };

            const req = await post(`/tasks/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                getTasks(selectedExpired, selectedToday, selectedAfter, selectedWithoutDue);
                handleCloseNewTaskModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const updateTask = async function () {
        if (checkEditTask()) {
            const task = {
                proceedingId: editTaskProceeding != '0' ? editTaskProceeding : null,
                userId: editTaskUser,
                dueDate: editTaskDate,
                alertId: editTaskReminder,
                title: document.getElementById("editTaskTitle").value,
                description: document.getElementById("editTaskDescription").value,
                isEvent: true,
                taskId: editTaskId
            };

            const req = await put(`/tasks/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                getTasks(selectedExpired, selectedToday, selectedAfter, selectedWithoutDue);
                handleCloseEditTaskModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }

            console.log(task)
        }
    }

    const checkEditTask = () => {
        let result = true;

        if (editTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("editTaskTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }

    const checkNewTask = () => {
        let result = true;

        if (newTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("newTaskTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const goToProceeding = (url) => {
        window.location.href = url;
    }

    const processFilter = () => {
        getTasks(selectedExpired, selectedToday, selectedAfter, selectedWithoutDue);
        handleCloseFilterModal()
    }

    const generateReport = async function () {
        handleCloseFilterModal()

        const body = {
            expired: selectedExpired,
            today: selectedToday,
            after: selectedAfter,
            lawer: selectedLawerFilter
        }

        const req = await post(`/tasks/report`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 200) {
            triggerBase64Download(res.file, 'Reporte')
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            console.log(res)
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td style={{ fontSize: 45, fontWeight: 300 }}>
                                    <span style={{ color: '#A0A0A0' }}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <Button className="ms-2" variant="primary" onClick={handleShowFilterModal}><FontAwesomeIcon icon={faFilter} style={{ marginRight: 8 }} />Filtros</Button>

                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success" onClick={handleShowNewTaskModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva tarea</Button>
                                    <Dropdown className='ms-2' style={{ display: 'inline' }}>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={generateReport}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar reporte</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </table>


                        {tasks.length > 0 ?
                            <div className='p-4'>
                                {tasks.map((t, i) => (
                                    <div className='file element mb-2' >
                                        <table>
                                            <tr>
                                                <td className='w-100'>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{t.proceedingName}</p>
                                                    <p className='m-0'>{t.title}</p>
                                                    <p className='m-0' style={{ fontSize: 12 }}>{t.description}</p>
                                                    <p className='mt-1 mb-0'>{timeDueWithBadge(t.dueDate)}</p>
                                                    <p className='mt-2 mb-0' style={{ fontSize: 12 }}><img className='me-1 avatar-20' style={{ objectFit: 'cover' }} src={`${params().dataUri}${t.User.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{t.User.name} {t.User.surname}</span></p>
                                                </td>
                                                <td className='w-100'>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => goToProceeding(t.proceedingUrl)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 7, marginLeft: 2 }} />Ir al expediente</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => handleShowEditTaskModal(t)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 7, marginLeft: 2 }} />Modificar</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => setTaskAsCompleted(t.id, t.proceedingId)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como completado</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                ))}
                            </div>
                            :
                            <Container className='mt-5 mb-5'>
                                <Row>
                                    <Col style={{ textAlign: 'center', fontSize: 60 }}>
                                        <FontAwesomeIcon icon={faTasks} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ textAlign: 'center' }}>
                                        No hay tareas
                                    </Col>
                                </Row>
                            </Container>
                        }


                    </Card>
                </Row>
            </Container>


            <Modal show={showNewTaskModal} onHide={handleCloseNewTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Expediente</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskProceeding(event)}>
                            <option value="0">Seleccionar...</option>
                            {proceedings.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.Status.name == 'Judicial' ? `J - ${p.CourtFile.name}` : `GDD - ${p.Debt.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs={3} style={{ textAlign: 'right' }}>
                                <ToggleButton
                                    className=""
                                    id="newTaskNotification"
                                    type="checkbox"
                                    variant="outline-success"
                                    checked={newTaskNotificationChecked}
                                    value="1"
                                    onChange={(e) => handleNewTaskNotification(e.currentTarget.checked)}
                                >
                                    Notificar
                                </ToggleButton>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newTaskTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newTaskDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={newTaskDate} onChange={(d) => handleNewTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createTask}>Crear tarea</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showFilterModal} onHide={handleCloseFilterModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Filtros</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Form.Group className=''>
                        <Form.Text>Vencimiento</Form.Text>
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Todas"
                            checked={selectedAll}
                            onChange={(event) => handleFilterShow(event, "all")}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Vencidas"
                            checked={selectedExpired}
                            onChange={(event) => handleFilterShow(event, "expired")}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Hoy"
                            checked={selectedToday}
                            onChange={(event) => handleFilterShow(event, "today")}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Próximas"
                            checked={selectedAfter}
                            onChange={(event) => handleFilterShow(event, "after")}
                        />
                        <Form.Check
                            className='mt-1'
                            type="checkbox"
                            id=""
                            label="Sin vencimiento"
                            checked={selectedWithoutDue}
                            onChange={(event) => handleFilterShow(event, "withoutdue")}
                        />
                    </Form.Group>

                    {getUserRole() == "Administrador" ?
                        <Form.Group className='mt-2'>
                            <Form.Text>Encargado</Form.Text>
                            <Form.Select className='cover content rows element' id="" defaultValue={selectedLawerFilter} onChange={(event) => handleLawerFilter(event)}>
                                <option key={0} id={0} value={0}>Todos</option>
                                {lawers.map((p, i) => (
                                    <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        :
                        ""
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseFilterModal}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={processFilter}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showEditTaskModal} onHide={handleCloseEditTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Expediente</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={editTaskProceeding} onChange={(event) => handleEditTaskProceeding(event)}>
                            <option value="0">Seleccionar...</option>
                            {proceedings.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.Status.name == 'Judicial' ? `J - ${p.CourtFile.name}` : `GDD - ${p.Debt.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' defaultValue={editTaskUser} onChange={(event) => handleEditTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="editTaskTitle" defaultValue={editTaskTitle} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="editTaskDescription" defaultValue={editTaskDescription} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={editTaskDate} onChange={(d) => handleEditTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={editTaskReminder} onChange={(event) => handleEditTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={updateTask}>Modificar</Button>


                </Modal.Footer>
            </Modal>
        </div>
    )
}