import React, {useEffect} from 'react'
import WebFont from 'webfontloader';
// eslint-disable-next-line
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {Container, Row, Card, Button, Table, Dropdown, DropdownButton, Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faUsers, faEye} from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import {get} from '../../../controllers/endpoints.controller';
import {getUserToken} from '../../../controllers/user.controller';

export default function Schools(){

    const PAGE_TITLE = "Familias";
    const PAGE_ICON = faUsers;
    const [tableColumns, setTableColumns] = React.useState([]);
    const [tableRows, setTableRows] = React.useState([]);
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC - ${PAGE_TITLE}`;
        getStatus();
    }, []);

    const getStatus = async function(){
        const req = await get('/status/debts', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            let result = {};
            for(let s of res){
                result[s.name] = s.color;
            }
            getFamilies(result);
        }else{
            alert(res.message);
        }
    }

    const getFamilies = async function(status){
        const req = await get('/persons/families', getUserToken());
        const res = await req.json();

        if(req.status === 200){
            let result = [];

            console.log(res);
            
            for(let r of res){
                r.parents = r.parents.split('\n');
                r.students = r.students.split('\n');
                r.debts = <div><Badge bg='' className='content-table status' style={{backgroundColor: `${status['Activo']}`}}>{r.debts.active}</Badge><Badge bg='' className='content-table status ms-2' style={{backgroundColor: `${status['En juicio']}`}}>{r.debts.onCourt}</Badge><Badge bg='' className='content-table status ms-2' style={{backgroundColor: `${status['Cerrado']}`}}>{r.debts.closed}</Badge></div>
                r.action = <Button className='' variant="primary"><FontAwesomeIcon icon={faEye} /></Button>;
                result.push(r);
            }
            setTableRows(result);
            setupTable();
        }else{
            alert(res.message);
        }
    }

    const setupTable = function(){
        const cols = ['Familia', 'Padres / Tutor', 'Alumnos', 'Moras', 'Acciones'];
        setTableColumns(cols);
    };

    return(
        <div className='p-0' style={{fontFamily: 'Roboto'}}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar/>

            <div style={{height: 300, background: '#FAFAFA'}}>
                <Container>
                    <Row style={{paddingTop: 10}}>
                        <table className='w-75' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                            <tr>
                                <td style={{fontSize: 45, fontWeight: 300}}>
                                <span style={{color: '#A0A0A0'}}>{PAGE_TITLE}</span>
                                </td>
                                <td style={{fontSize: 140, width: 150}}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{fontSize: 140, color: "A0A0A0"}} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{marginTop: -70}}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <DropdownButton id="dropdown-basic-button" variant='light' title="Ordenar por nombre">
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </DropdownButton>
                                </td>
                                <td className='content-options rows right'>
                                    <Button variant="success"><FontAwesomeIcon icon={faPlusCircle} style={{marginRight: 8}} />Nueva familia</Button>
                                </td>
                            </tr>
                        </table>

                        <table striped hover className='mb-3'>
                            <thead>
                                <tr>
                                    {tableColumns.map((c, i) => (
                                        <th key={i} className='content-table head'>{c}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableRows.map((r, i) => (
                                    <tr key={i} >
                                        <td className='content-table rows middle align-middle'>{r.name}</td>
                                        <td className='content-table rows middle align-middle'>{r.parents.map((pr, i) => (<p className='m-0'>{pr}</p>))}</td>
                                        <td className='content-table rows middle align-middle'>{r.students.map((st, i) => (<p className='m-0'>{st}</p>))}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.debts}</td>
                                        <td className='content-table rows middle align-middle' style={{width: 150}}>{r.action}</td>
                                    </tr>
                                ))}
                                
                            </tbody>
                        </table>
                    </Card>
                </Row>
            </Container>
        </div>
    )
}