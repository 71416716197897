import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import './App.css';

import Login from "./components/ui/login/Login";
import Index from "./components/ui/home/Index";
import Home from "./components/ui/home/Home";
import HomeCustomers from "./components/ui/customers/Home";
import Families from "./components/ui/families/Families";
import Debts from "./components/ui/debts/Debts";
import MyDebts from "./components/ui/debts/MyDebts";
import DebtDetails from "./components/ui/debts/DebtDetails";
import NewDebtStep1 from "./components/ui/debts/NewDebtStep1";
import NewDebtStep2 from "./components/ui/debts/NewDebtStep2";
import NewDebtStep3 from "./components/ui/debts/NewDebtStep3";
import CourtFiles from "./components/ui/courtFiles/CourtFiles";
import MyCourtFiles from "./components/ui/courtFiles/MyCourtFiles";
import NewCourtFileStep1 from "./components/ui/courtFiles/NewCourtFileStep1";
import NewCourtFileStep2 from "./components/ui/courtFiles/NewCourtFileStep2";
import NewCourtFileStep3 from "./components/ui/courtFiles/NewCourtFileStep3";
import CourtFileDetails from "./components/ui/courtFiles/CourtFileDetails";
import Calendar from "./components/ui/calendar/Calendar";
import Tasks from "./components/ui/tasks/Tasks";
import Administration from "./components/ui/administration/Administration";
import CashFlow from "./components/ui/administration/CashFlow";
import AdministrationByCustomer from "./components/ui/administration/AdministrationByCustomer";
import MyCurrentAccount from "./components/ui/administration/MyCurrentAccount";
import Persons from "./components/ui/defendants/Persons";
import PersonDetails from "./components/ui/defendants/PersonDetails";
import NewPerson from "./components/ui/defendants/NewPerson";
import Business from "./components/ui/defendants/Business";
import BusinessDetails from "./components/ui/defendants/BusinessDetails";
import NewBusiness from "./components/ui/defendants/NewBusiness";
import Archived from "./components/ui/archived/Archived";
import Customers from "./components/ui/defendants/Customers";
import Defendants from "./components/ui/defendants/Defendants";
import Settings from "./components/ui/settings/Settings";
import NewUser from "./components/ui/settings/NewUser";
import UserDetails from "./components/ui/settings/UserDetails";
import GoogleCalendarIntegration from "./components/ui/settings/GoogleCalendarIntegration";
import UserSettings from "./components/ui/settings/UserSettings";
import MyUserDetails from "./components/ui/settings/MyUserDetails";
import SelectBusiness from "./components/ui/login/SelectBusiness";
import { getUserRole } from "./controllers/user.controller";

export function App() {

    const ProcessRoutes = () => {
        let r = getUserRole();

        if (r == "Administrador") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/moras" element={<Debts />} />
                    <Route path="/moras/detalles" element={<DebtDetails />} />
                    <Route path="/moras/nueva/1" element={<NewDebtStep1 />} />
                    <Route path="/moras/nueva/2" element={<NewDebtStep2 />} />
                    <Route path="/moras/nueva/3" element={<NewDebtStep3 />} />
                    <Route path="/expedientes" element={<CourtFiles />} />
                    <Route path="/expedientes/detalles" element={<CourtFileDetails />} />
                    <Route path="/judicial/nueva/1" element={<NewCourtFileStep1 />} />
                    <Route path="/judicial/nueva/2" element={<NewCourtFileStep2 />} />
                    <Route path="/judicial/nueva/3" element={<NewCourtFileStep3 />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/tareas" element={<Tasks />} />
                    <Route path="/administracion" element={<Administration />} />
                    <Route path="/administracion/flujocaja" element={<CashFlow />} />
                    <Route path="/administracion/cliente" element={<AdministrationByCustomer />} />
                    <Route path="/demandados/personas" element={<Defendants />} />
                    <Route path="/demandados/personas/nueva" element={<NewPerson customers={false} />} />
                    <Route path="/demandados/personas/detalles/" element={<PersonDetails customers={false} />} />
                    <Route path="/demandados/empresas" element={<Business customers={false} />} />
                    <Route path="/demandados/empresas/nueva" element={<NewBusiness customers={false} />} />
                    <Route path="/demandados/empresas/detalles" element={<BusinessDetails customers={false} />} />
                    <Route path="/archivo" element={<Archived />} />
                    <Route path="/clientes/personas" element={<Customers />} />
                    <Route path="/clientes/personas/nueva" element={<NewPerson customers={true} />} />
                    <Route path="/clientes/personas/detalles/" element={<PersonDetails customers={true} />} />
                    <Route path="/clientes/empresas" element={<Business customers={true} />} />
                    <Route path="/clientes/empresas/nueva" element={<NewBusiness customers={true} />} />
                    <Route path="/clientes/empresas/detalles" element={<BusinessDetails customers={true} />} />
                    <Route path="/sistema" element={<Settings />} />
                    <Route path="/sistema/usuarios/nuevo" element={<NewUser />} />
                    <Route path="/sistema/usuarios/detalles" element={<UserDetails />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/integraciones/google/calendar/" element={<GoogleCalendarIntegration />} />
                    <Route path="/configuracion" element={<UserSettings />} />
                </Routes>
            )
        } else if (r == "Empleado") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/moras" element={<Debts />} />
                    <Route path="/moras/detalles" element={<DebtDetails />} />
                    <Route path="/moras/nueva/1" element={<NewDebtStep1 />} />
                    <Route path="/moras/nueva/2" element={<NewDebtStep2 />} />
                    <Route path="/moras/nueva/3" element={<NewDebtStep3 />} />
                    <Route path="/expedientes" element={<CourtFiles />} />
                    <Route path="/expedientes/detalles" element={<CourtFileDetails />} />
                    <Route path="/judicial/nueva/1" element={<NewCourtFileStep1 />} />
                    <Route path="/judicial/nueva/2" element={<NewCourtFileStep2 />} />
                    <Route path="/judicial/nueva/3" element={<NewCourtFileStep3 />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/tareas" element={<Tasks />} />
                    <Route path="/demandados/personas" element={<Defendants />} />
                    <Route path="/demandados/personas/nueva" element={<NewPerson customers={false} />} />
                    <Route path="/demandados/personas/detalles/" element={<PersonDetails customers={false} />} />
                    <Route path="/demandados/empresas" element={<Business customers={false} />} />
                    <Route path="/demandados/empresas/nueva" element={<NewBusiness customers={false} />} />
                    <Route path="/demandados/empresas/detalles" element={<BusinessDetails customers={false} />} />
                    <Route path="/archivo" element={<Archived />} />
                    <Route path="/clientes/personas" element={<Customers />} />
                    <Route path="/clientes/personas/nueva" element={<NewPerson customers={true} />} />
                    <Route path="/clientes/personas/detalles/" element={<PersonDetails customers={true} />} />
                    <Route path="/clientes/empresas" element={<Business customers={true} />} />
                    <Route path="/clientes/empresas/nueva" element={<NewBusiness customers={true} />} />
                    <Route path="/clientes/empresas/detalles" element={<BusinessDetails customers={true} />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/integraciones/google/calendar/" element={<GoogleCalendarIntegration />} />
                    <Route path="/configuracion" element={<UserSettings />} />
                </Routes>)
        } else if (r == "Empleado Full") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/administracion" element={<Administration />} />
                    <Route path="/administracion/flujocaja" element={<CashFlow />} />
                    <Route path="/administracion/cliente" element={<AdministrationByCustomer />} />
                    <Route path="/moras" element={<Debts />} />
                    <Route path="/moras/detalles" element={<DebtDetails />} />
                    <Route path="/moras/nueva/1" element={<NewDebtStep1 />} />
                    <Route path="/moras/nueva/2" element={<NewDebtStep2 />} />
                    <Route path="/moras/nueva/3" element={<NewDebtStep3 />} />
                    <Route path="/expedientes" element={<CourtFiles />} />
                    <Route path="/expedientes/detalles" element={<CourtFileDetails />} />
                    <Route path="/judicial/nueva/1" element={<NewCourtFileStep1 />} />
                    <Route path="/judicial/nueva/2" element={<NewCourtFileStep2 />} />
                    <Route path="/judicial/nueva/3" element={<NewCourtFileStep3 />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/tareas" element={<Tasks />} />
                    <Route path="/demandados/personas" element={<Defendants />} />
                    <Route path="/demandados/personas/nueva" element={<NewPerson customers={false} />} />
                    <Route path="/demandados/personas/detalles/" element={<PersonDetails customers={false} />} />
                    <Route path="/demandados/empresas" element={<Business customers={false} />} />
                    <Route path="/demandados/empresas/nueva" element={<NewBusiness customers={false} />} />
                    <Route path="/demandados/empresas/detalles" element={<BusinessDetails customers={false} />} />
                    <Route path="/archivo" element={<Archived />} />
                    <Route path="/clientes/personas" element={<Customers />} />
                    <Route path="/clientes/personas/nueva" element={<NewPerson customers={true} />} />
                    <Route path="/clientes/personas/detalles/" element={<PersonDetails customers={true} />} />
                    <Route path="/clientes/empresas" element={<Business customers={true} />} />
                    <Route path="/clientes/empresas/nueva" element={<NewBusiness customers={true} />} />
                    <Route path="/clientes/empresas/detalles" element={<BusinessDetails customers={true} />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/integraciones/google/calendar/" element={<GoogleCalendarIntegration />} />
                    <Route path="/configuracion" element={<UserSettings />} />
                </Routes>)
        }else if (r == "Empleado Judicial") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/expedientes" element={<CourtFiles />} />
                    <Route path="/expedientes/detalles" element={<CourtFileDetails />} />
                    <Route path="/judicial/nueva/1" element={<NewCourtFileStep1 />} />
                    <Route path="/judicial/nueva/2" element={<NewCourtFileStep2 />} />
                    <Route path="/judicial/nueva/3" element={<NewCourtFileStep3 />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/tareas" element={<Tasks />} />
                    <Route path="/demandados/personas" element={<Defendants />} />
                    <Route path="/demandados/personas/nueva" element={<NewPerson customers={false} />} />
                    <Route path="/demandados/personas/detalles/" element={<PersonDetails customers={false} />} />
                    <Route path="/demandados/empresas" element={<Business customers={false} />} />
                    <Route path="/demandados/empresas/nueva" element={<NewBusiness customers={false} />} />
                    <Route path="/demandados/empresas/detalles" element={<BusinessDetails customers={false} />} />
                    <Route path="/archivo" element={<Archived />} />
                    <Route path="/clientes/personas" element={<Customers />} />
                    <Route path="/clientes/personas/nueva" element={<NewPerson customers={true} />} />
                    <Route path="/clientes/personas/detalles/" element={<PersonDetails customers={true} />} />
                    <Route path="/clientes/empresas" element={<Business customers={true} />} />
                    <Route path="/clientes/empresas/nueva" element={<NewBusiness customers={true} />} />
                    <Route path="/clientes/empresas/detalles" element={<BusinessDetails customers={true} />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/integraciones/google/calendar/" element={<GoogleCalendarIntegration />} />
                    <Route path="/configuracion" element={<UserSettings />} />
                </Routes>)
        }else if (r == "Empleado Deudas") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                    <Route path="/familias" element={<Families />} />
                    <Route path="/moras" element={<Debts />} />
                    <Route path="/moras/detalles" element={<DebtDetails />} />
                    <Route path="/moras/nueva/1" element={<NewDebtStep1 />} />
                    <Route path="/moras/nueva/2" element={<NewDebtStep2 />} />
                    <Route path="/moras/nueva/3" element={<NewDebtStep3 />} />
                    <Route path="/calendario" element={<Calendar />} />
                    <Route path="/tareas" element={<Tasks />} />
                    <Route path="/demandados/personas" element={<Defendants />} />
                    <Route path="/demandados/personas/nueva" element={<NewPerson customers={false} />} />
                    <Route path="/demandados/personas/detalles/" element={<PersonDetails customers={false} />} />
                    <Route path="/demandados/empresas" element={<Business customers={false} />} />
                    <Route path="/demandados/empresas/nueva" element={<NewBusiness customers={false} />} />
                    <Route path="/demandados/empresas/detalles" element={<BusinessDetails customers={false} />} />
                    <Route path="/archivo" element={<Archived />} />
                    <Route path="/clientes/personas" element={<Customers />} />
                    <Route path="/clientes/personas/nueva" element={<NewPerson customers={true} />} />
                    <Route path="/clientes/personas/detalles/" element={<PersonDetails customers={true} />} />
                    <Route path="/clientes/empresas" element={<Business customers={true} />} />
                    <Route path="/clientes/empresas/nueva" element={<NewBusiness customers={true} />} />
                    <Route path="/clientes/empresas/detalles" element={<BusinessDetails customers={true} />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/integraciones/google/calendar/" element={<GoogleCalendarIntegration />} />
                    <Route path="/configuracion" element={<UserSettings />} />
                </Routes>)
        }else if (r == "Cliente") {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/seleccionar" element={<SelectBusiness />} />
                    <Route path="/inicio" element={<HomeCustomers />} />
                    <Route path="/moras" element={<MyDebts />} />
                    <Route path="/moras/detalles" element={<DebtDetails />} />
                    <Route path="/expedientes" element={<HomeCustomers />} />
                    <Route path="/expedientes/detalles" element={<CourtFileDetails />} />
                    <Route path="/configuracion/usuarios/detalles" element={<MyUserDetails />} />
                    <Route path="/administracion" element={<MyCurrentAccount />} />

                </Routes>)
        } else {
            return (
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<Index />} />
                    <Route path="/inicio" element={<Home />} />
                </Routes>)
        }
    }

    return (
        <div>
            <Router>
                <Row className="m-0">

                    <ProcessRoutes />


                </Row>
            </Router>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossOrigin="anonymous" />
        </div>
    );
}