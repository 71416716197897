import React, { useEffect } from 'react'
import { Form, Button, Container, Row, Col, Modal, Alert } from "react-bootstrap";
import { post } from '../../../controllers/endpoints.controller';
import { getUserBusinesses, saveUserBusiness, saveUserData } from '../../../controllers/user.controller';
import WebFont from 'webfontloader';


export default function SelectBusiness() {
    const [businesses, setBusinesses] = React.useState([]);

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });

        document.title = `EAC`;
        getBusinesses();
    }, []);

    const getBusinesses = function () {
        const b = getUserBusinesses();
        setBusinesses(b);
    }

    const selectBusiness = function (id, name) {
        saveUserBusiness(id, name);
        window.location.href = '/inicio';
    }

    return (
        <div>
            <Row style={{ height: '100vh' }}>
                <Col xs={4}>
                </Col>
                <Col xs={4}>
                    <Container id='login-form' style={{ textAlign: 'center' }}>
                        <div style={{ textAlign: 'center' }}><img width={300} src='https://eac.com.ar/wp-content/uploads/2022/01/Logos-1_6AC-sf.png'></img></div>
                        <p className='mt-5 mb-3'>Detectamos que tenés varios colegios a cargo, seleccioná uno para continuar:</p>
                        {businesses.map((b, i) => (
                            <Button onClick={() => selectBusiness(b.id, b.legalName)} className='buttonBlue w-100 mt-2' variant="primary">{b.legalName}</Button>
                        ))}
                    </Container>
                </Col>
                <Col xs={4}>
                </Col>
            </Row>


        </div>
    );
}